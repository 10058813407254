import { Link } from "gatsby"
import React from "react"
import { Container } from "react-bootstrap"
import useNews from "../../hooks/dataHooks/useNews"
import "./NewsListing.scss"
import NewsCard from "../NewsCard/NewsCard"
import AppraisalComponent from "../AppraisalComponent/AppraisalComponent"
import { newsBlogsURL, teamsURL } from "../../common/utils/urls"
import { format } from "date-fns"
import useDeviceMedia from "../../hooks/useDeviceMedia"
import usePagination from "../../hooks/usePagination"
import Pagination from "../Pagination/Pagination"
import Animation from "../elements/Animation"
import GGFXImage from "../elements/GGFXImage"

const NewsListing = ({ module }) => {
  const allNews = useNews()

  const latestNews = allNews[0]

  const {
    tile_image,
    title,
    slug,
    description,
    date,
    author,
    blog_category,
    strapi_id,
  } = latestNews

  const formattedDate = format(new Date(date), "MMMM do, yyyy")

  const filteredNews = allNews && latestNews?.strapi_id
    ? allNews.filter(item => item.strapi_id !== latestNews.strapi_id)
    : [];

  const { isMobile, isTablet } = useDeviceMedia()

  const itemsPerPage = isMobile ? 12 : isTablet ? 10 : 9
  const paginationProps = usePagination({
    items: filteredNews,
    itemsPerPage,
  })

  if (!module || !allNews) return null

  let processedImages = latestNews?.imagetransforms?.tile_image_Transforms
  processedImages ||= JSON.stringify({})

  return (
    <>
      <div className="newslisting-wrap">
        <Container className="newslisting-container">
          {latestNews && (
            <Animation animateInType={"up"} className="featured-news-wrap">
              <Link
                to={`${newsBlogsURL}${slug}/`}
                className="news-img img-zoom"
              >
                <GGFXImage
                  ImageSrc={tile_image}
                  title={title}
                  altText={title}
                  imagetransforms={processedImages}
                  renderer="srcSet"
                  imagename={"blog.tile_image.featured_image"}
                  strapi_id={strapi_id}
                />
              </Link>
              <div className="news-content">
                <h4 className="category">{blog_category.name}</h4>
                <h2 className="title">{title}</h2>
                <div className="date-author-section">
                  <p className="date">{formattedDate}</p>
                  <span>/</span>
                  <p className="author">
                    by{" "}
                    <Link to={`${teamsURL + author.slug}/`}>{author.name}</Link>
                  </p>
                </div>
                <p className="description">{description}</p>
              </div>
            </Animation>
          )}
          <div className="news-list-wrap">
            {paginationProps.currentItems.map((news, index) => (
              <NewsCard news={news} index={index} />
            ))}
            <Pagination {...paginationProps} />
          </div>
        </Container>
      </div>
      <AppraisalComponent />
    </>
  )
}

export default NewsListing
